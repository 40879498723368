/* src/components/PostGrid.css */

.post-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 1px;
    padding: 20px;
    margin-left: 120px;
  }
  
  .post-grid-item {
    position: relative;
    width: 100%; /* Full width of the grid cell */
    padding-top: 100%; /* Maintain square aspect ratio using padding-top */
    overflow: hidden; /* Hide any overflow */
    background-color: #f0f0f0; /* Optional: Background color to visualize grid items */
  }

  .post-image {
    position: absolute; /* Position absolute to cover the grid item */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the container without distortion */
    cursor: pointer; /* Optional: Pointer cursor */
  }