/* src/components/Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto; /* Scroll if content exceeds modal */
  }
  
  /* .modal-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  } */
  .modal-image {
    max-width: 100%; /* Scale down the image to fit modal width */
    max-height: 80vh; /* Scale down the image to fit modal height */
    object-fit: contain; /* Keep the aspect ratio and fit within the container */
    margin-bottom: 10px; /* Space between image and caption */
  }
  
  .modal-caption {
    margin-top: 10px;
    text-align: center;
  }
  
  .modal-close {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  