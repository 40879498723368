/* src/styles/Navigation.css */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');


.nav {
    display: flex;
    flex-direction: column; /* Default to vertical for desktop */
    align-items: center;
    padding: 20px;
    position: fixed; /* Keep it fixed on the left side of the screen */
    top: 0;
    left: 0;
    height: 100vh; /* Full height on the left side for desktop */
    background-color: #fff;
    border-right: 1px solid #ddd;
  }
  
  .profile-container {
    margin-bottom: 20px;
  }
  
  .profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #007bff;
  }
  
  .nav-link {
    text-decoration: none;
    color: #007bff;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .nav-link.active {
    color: #fff; /* Active link text color */
    background-color: #007bff; /* Active link background */
    border-radius: 8px; /* Round corners for the active link */
    padding: 10px; /* Add padding to active link */
  }
  
  .nav-link:hover {
    color: #0056b3; /* Darker color on hover */
  }
  
  .instagram-logo {
    font-family: 'Pacifico', cursive; /* Apply the imported font */
    font-size: 24px; /* Adjust the font size */
    color: #262626; /* Instagram logo color */
    margin-bottom: 20px; /* Space below the logo */
  }